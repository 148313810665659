@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700&display=swap');

body {
    position: relative;
    margin: 0;
    font-family: 'Almarai', sans-serif;
}

main {
    position: absolute;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 32px;
    opacity: 0;
}

.container a {
    color: darkolivegreen;
}

h1 {
    font-weight: 400;
}

.socialmedia {
    position: absolute;
    bottom: 32px;
    display: flex;
    text-align: center;
    justify-content: center;
    left: 50%;
    transform: translate(-50%);
    gap: 32px;
}

.socialmedia-item {
    bottom: 32px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
}

.socialmedia-item img {
    width: 40px;
    transition: all ease-in-out 0.3s;
}

.socialmedia-item img:hover {
    transform: scale(1.2);
}


.socialmedia-item p {
    text-transform: uppercase;
}

#messageContainer {
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    color: white;
}

#messageContainer div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(61, 143, 81, 0.7);
    text-align: center;
    max-width: 700px;
    padding: 16px 32px;
}


#messageContainer p {
    font-size: 18px;
}

#messageContainer button {
    border: none;
    background-color: rgb(52, 114, 68);
    padding: 8px;
    font-size: 14px;
    cursor: pointer;
    color: white;

}

#messageContainer button:hover {
    background-color: rgb(36, 90, 49);
}

#wind {
    z-index: 1;
    position: absolute;
    right: 32px;
    top: 32px;
    display: flex;
    opacity: 0;
}


#devInfo {
    text-align: center;
    max-width: 400px;
    min-width: 310px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    display: none;
    flex-direction: column;
    opacity: 0;
    z-index: -1;
}

#devInfo h2 {
    font-weight: 400;
}

#devSocial {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    justify-content: center;
    align-items: center;
    gap: 32px;
    transform: translate(-50%, -50%);
    z-index: 1;
    opacity: 0;
}

#devSocial a {
    color: black;
}

audio {
    z-index: -5;
    opacity: 0;
    position: absolute;

}

#loading {
    display: inline-block;
    width: 80px;
    height: 80px;
}

#loading div {
    position: absolute;
    border: 4px solid black;
    opacity: 1;
    border-radius: 50%;
    animation: loading 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

#loading div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes loading {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }

    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }

    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

#playButton {
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    opacity: 0;
}
