@import "https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700&display=swap";
body {
  margin: 0;
  font-family: Almarai, sans-serif;
  position: relative;
}

main {
  z-index: -1;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.container {
  max-width: 600px;
  text-align: center;
  opacity: 0;
  background-color: #ffffff80;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  display: flex;
}

.container a {
  color: #556b2f;
}

h1 {
  font-weight: 400;
}

.socialmedia {
  text-align: center;
  justify-content: center;
  gap: 32px;
  display: flex;
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translate(-50%);
}

.socialmedia-item {
  color: #000;
  align-items: center;
  text-decoration: none;
  display: flex;
  bottom: 32px;
}

.socialmedia-item img {
  width: 40px;
  transition: all .3s ease-in-out;
}

.socialmedia-item img:hover {
  transform: scale(1.2);
}

.socialmedia-item p {
  text-transform: uppercase;
}

#messageContainer {
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0;
  color: #fff;
  background-color: #000000b3;
  justify-content: center;
  align-items: center;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

#messageContainer div {
  text-align: center;
  max-width: 700px;
  background-color: #3d8f51b3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  display: flex;
}

#messageContainer p {
  font-size: 18px;
}

#messageContainer button {
  cursor: pointer;
  color: #fff;
  background-color: #347244;
  border: none;
  padding: 8px;
  font-size: 14px;
}

#messageContainer button:hover {
  background-color: #245a31;
}

#wind {
  z-index: 1;
  opacity: 0;
  display: flex;
  position: absolute;
  top: 32px;
  right: 32px;
}

#devInfo {
  text-align: center;
  max-width: 400px;
  min-width: 310px;
  opacity: 0;
  z-index: -1;
  flex-direction: column;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
}

#devInfo h2 {
  font-weight: 400;
}

#devSocial {
  z-index: 1;
  opacity: 0;
  justify-content: center;
  align-items: center;
  gap: 32px;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#devSocial a {
  color: #000;
}

audio {
  z-index: -5;
  opacity: 0;
  position: absolute;
}

#loading {
  width: 80px;
  height: 80px;
  display: inline-block;
}

#loading div {
  opacity: 1;
  border: 4px solid #000;
  border-radius: 50%;
  animation: 1s cubic-bezier(0, .2, .8, 1) infinite loading;
  position: absolute;
}

#loading div:nth-child(2) {
  animation-delay: -.5s;
}

@keyframes loading {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    top: 36px;
    left: 36px;
  }

  4.9% {
    width: 0;
    height: 0;
    opacity: 0;
    top: 36px;
    left: 36px;
  }

  5% {
    width: 0;
    height: 0;
    opacity: 1;
    top: 36px;
    left: 36px;
  }

  100% {
    width: 72px;
    height: 72px;
    opacity: 0;
    top: 0;
    left: 0;
  }
}

#playButton {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*# sourceMappingURL=index.6694537a.css.map */
